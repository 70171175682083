<template>
  <div class="prepare-selfie fill-height">

    <v-row class="fill-height">
      <left-side-image/>
      <v-col cols="12" md="7">

        <nav-bar-page/>

        <v-row justify="center" class="mt-0">
          <v-col cols="12" class="mt-0 py-0">
            <div class="my-0 pt-0 primary--text">
              <h1 class="text-center today-regular text-title">¡PAGINA NO ENCONTRADA!</h1>
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

  </div>

</template>

<script>
import LeftSideImage from "@/components/Layouts/LeftSideImage";
import NavBarPage from "@/components/Layouts/NavBarPage";
import {locals} from "@/helpers/locals";

export default {
  name: 'PrepareSelfie',
  components: {LeftSideImage, NavBarPage},
  data() {
    return {
      loading: false
    }
  },
  mounted() {
    locals.clearLocal();
    this.$router.push('/');
  },
  methods: {
  }
}
</script>
<style scoped>

li::marker {
  color: #FFD100;
}

</style>