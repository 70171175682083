<template>
  <div class="contact-data fill-height">

    <v-row class="fill-height">
      <left-side-image/>
      <v-col cols="12" md="7">

        <nav-bar-page/>

        <v-container class="pt-0">

          <v-row justify="center" class="mt-0 pt-0">

            <v-col cols="12" md="12" lg="12" class="pt-0">


              <iframe src="https://adocolombia-qa.ado-tech.com/CoopcentralQA/validar-persona?callback=http://localhost:8000/api/ado-webhook&key=27247ABA50A1E51&projectName=TCDigital_BCC_QA&product=1"
                      style="overflow:hidden;overflow-x:hidden;overflow-y:hidden;width:100%;" height="520" width="100%" frameborder="0" title="description"
                      allow="camera">
              </iframe>


            </v-col>
          </v-row>

        </v-container>
      </v-col>
    </v-row>

  </div>
</template>

<script>
import LeftSideImage from "@/components/Layouts/LeftSideImage";
import NavBarPage from "@/components/Layouts/NavBarPage";

export default {
  name: 'DevPage',
  components: {LeftSideImage, NavBarPage},
  data() {
    return {
      windowHeight: window.innerHeight,
      windowWidth: (window.innerWidth*0.55)
    }
  },
  mounted() {

    window.Echo.channel('ado-coopcentral')
        .listen('AdoCoopcentral', (e) => {
          console.log(e)
        })

    navigator.mediaDevices.getUserMedia({ video: true })
        .then(function(stream) {
          var video = document.querySelector('video');
          video.srcObject = stream;
          video.play();
        })
        .catch(function(err) {
          console.log('Error: ' + err);
        });

  },
  methods: {

  }
}
</script>

<style scoped lang="scss">


</style>
